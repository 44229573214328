// import Nav from 'react-bootstrap/Nav';
// import logo from '../components/img/logo.png'
// import { Navbar } from 'react-bootstrap';

// function BasicExample() {
//   return (
   
//     <div className='container-fluid' >
//       <section  style={{background:'#2AA7FF'}}>
//       <div className='container'>
//     <div className='row'>
// <div className='col-lg-12'>
//   <p className='main-p'>The health and well-being of our patients and their health care team will always be our priority, 
//     so we follow the best practices for cleanliness.</p>
//   </div>
//   </div>
//   </div></section>
//   <section  style={{background:'#fff'}}>
//   <div className='container'>
//   <div className='row'>
//   <div className='col-lg-2'>
//   <Navbar bg="light" expand="lg">
//       <Navbar.Brand href="#home">
      
//        <img src={logo } /> 
//        </Navbar.Brand>
//       </Navbar>
//   </div>
 
//   <div className='col-lg-6'>
//    <Navbar bg="light" expand="lg" >
//       <Nav className="ml-auto">
//       <Nav.Item>
//           <Nav.Link href="/Home">Home</Nav.Link>
//         </Nav.Item>
//         <Nav.Item>
//           <Nav.Link href="/Find">Find Doctor</Nav.Link>
//         </Nav.Item>
//         <Nav.Item>
//           <Nav.Link eventKey="link-1">Hospitals</Nav.Link>
//         </Nav.Item>
//         <Nav.Item>
//           <Nav.Link eventKey="link-2">Medicine</Nav.Link>
//         </Nav.Item>
        
//         <Nav.Item>
//           <Nav.Link eventKey="link-2">Health</Nav.Link>
//         </Nav.Item>
       
//       </Nav>
//     </Navbar>
//     </div>
//     <div className='col-lg-3'>
//     <Navbar bg="light" expand="lg">
//       <Nav className="ml-auto">
//         <div className="ml-auto d-flex">
//        <Nav.Item className='btn-bg'>
//             <Nav.Link href="/Signin">Login / Sign Up</Nav.Link>
//           </Nav.Item>&nbsp;&nbsp;
//           <Nav.Item className='btn-bg01'>
//             <Nav.Link href="/Signin">Go To Cart</Nav.Link>
//           </Nav.Item>
//            </div>
//       </Nav>
//     </Navbar>
//     </div>
//   </div>
//     </div>
//     </section>
//     </div>
//   );
// }

// export default BasicExample;
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../components/img/logo.png';
import { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import Topbar from './topbar';

function BasicExample() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
 
    <>
      <Topbar />
      <section >
        <div className='container'>
          <div className='row align-items-center'>
            {/* Logo */}
            <div className='col-lg-2 col-6'>
              <Navbar bg="light" expand="lg">
                <Navbar.Brand href="#home">
                  <img src={logo} alt="Logo" style={{ width: '100%' }} />
                </Navbar.Brand>
              </Navbar>
            </div>

            {/* Main Navigation for Desktop */}
            <div className='col-lg-6 d-none d-lg-block'>
              <Navbar bg="light" expand="lg">
                <Nav className="ml-auto">
                  <Nav.Item>
                    <Nav.Link href="/Home">Home</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/Find">Find Doctor</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#Hospitals">Hospitals</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#Medicine">Medicine</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#Health">Health</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar>
            </div>

            {/* Action Buttons for Desktop */}
            <div className='col-lg-3 d-none d-lg-block'>
              <Navbar bg="light" expand="lg">
                <Nav className="ml-auto">
                  <div className="ml-auto d-flex">
                    <Nav.Item className='btn-bg'>
                      <Nav.Link href="/Signin">Login / Sign Up</Nav.Link>
                    </Nav.Item>
                    &nbsp;&nbsp;
                    <Nav.Item className='btn-bg01'>
                      <Nav.Link href="/Cart">Go To Cart</Nav.Link>
                    </Nav.Item>
                  </div>
                </Nav>
              </Navbar>
            </div>

            {/* Hamburger Menu for Mobile */}
            <div className='col-6 d-lg-none text-right'>
              <button className='navbar-toggler' type='button' onClick={handleShow}>
                {/* <span className='navbar-toggler-icon'></span> */}
                <i class="bi bi-list"></i>
              </button>
            </div>
          </div>
        </div>
     

      
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Item>
              <Nav.Link href="/Home">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/Find">Find Doctor</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#Hospitals">Hospitals</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#Medicine">Medicine</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#Health">Health</Nav.Link>
            </Nav.Item>
            <Nav.Item className='mt-4'>
              <Nav.Link href="/Signin" className='btn-bg'>
                Login / Sign Up
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/Cart" className='btn-bg01'>
                Go To Cart
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
      </section>
      </>
  );
}

export default BasicExample;
