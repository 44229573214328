// import React, { useState, useEffect } from 'react';
// import Button from 'react-bootstrap/Button';
// import img from './img/john.png';
// import dt from './img/dt.png'
// import rd from './img/rd.png'

// const Doctors = () => {
//   const [isPopupOpen, setIsPopupOpen] = useState(false);

//   // Function to toggle the popup
//   const togglePopup = () => {
//     setIsPopupOpen(!isPopupOpen);
//   };
//   // State for toggling the appointment form div
//   const [isDivVisible, setIsDivVisible] = useState(false);

//   // Simulate fetching data
//   const [data, setData] = useState([]);

//   const jsonData = [
//     {
//       dts: dt,
//       img1: './img/john.png',
//       name: 'John Doe',
//       jd: 'Sergon',
//       exp: '16 years Of Experience',
//       consulting: 'Consultation fee at clinic',
//       Timing: 'Mon - Fri | 8 AM to 2 PM',
//     },
//     {
//       dts: rd,
//       img1: '',
//       name: 'Jennifer',
//       jd: 'Sergon',
//       exp: '10 years Of Experience',
//       consulting: 'Consultation fee at clinic',
//       Timing: 'Mon - Web | 10 AM to 12 PM',
//     },
//     {
//       dts: rd,
//       img1: './img/john.png',
//       name: 'John Doe',
//       jd: 'Sergon',
//       exp: '8 years Of Experience',
//       consulting: 'Consultation fee at clinic',
//       Timing: 'Mon - Thurs | 8 AM to 2 PM',
//     },
//   ];

//   useEffect(() => {
//     // Simulate fetching data
//     setTimeout(() => {
//       setData(jsonData);
//     }, 1000);
//   }, []);

//   // Function to toggle the appointment form visibility
//   const handleToggleDiv = () => {
//     setIsDivVisible(!isDivVisible);
//   };

//   return (
//     <div className="container-fluid bg-clr bg-res" style={{marginTop: -255}}>
//       <div className="container cntr">
//         <div className="row">
//           <div className="col-lg-6 col-md-6 col-sm-12 col-12">
//             <div style={{ height: 50 }}></div>
//             <h5 className="pat2" style={{textAlign:'left'}}>30 Doctors Near By You</h5>
//             <p className="para2" style={{textAlign:'left'}}>
//               Book appointments with minimum wait-time & verified doctor details
//             </p>
//           </div>
//         </div>

//         {data.length > 0 ? (
//           data.map((user, index) => (
//             <div className="row" key={index}>
//               <div className="col-lg-10 col-md-10 col-sm-12 col-12 d-info">
//                 <div className="row">
//                   <div className="col-lg-3 col-md-3 col-sm-12 col-12 dr-img">
//                     <div className="img-bdr">
                     
//                       <img src={img} alt="" className="mimg02" />
//                       <img src={user.dts} alt="" className="mimg1" />
                      
//                     </div>
//                   </div>
//                   <div className="col-lg-6 col-md-6 col-sm-12 col-12">
//                     <div className="div-des">
//                       <h4 className="pat">{user.name}</h4>
//                       <p className="para2">{user.jd}</p>
//                       <p className="para2">{user.exp}</p>
//                       <p className="pat2">{user.consulting}</p>
//                       <p className="pat4">&nbsp;{user.Timing}</p>
//                     </div>
//                     {/* Toggle this div based on isDivVisible */}
//                     {isDivVisible && (
//                       <div id="toggleDiv">
//                         <form>
//                           <table>
//                             <tr>
//                               <td>Available Time Slots</td>
//                             </tr>
//                             <tr>
//                               <td>
//                                 <label className="form-check-label">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input"
//                                     value=""
//                                   />{' '}
//                                   Mon 10 AM to 12 PM
//                                 </label>
//                               </td>
//                               <td>
//                                 <label className="form-check-label">
//                                   &nbsp;&nbsp;&nbsp;
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input"
//                                     value=""
//                                   />{' '}
//                                   Tues 10 AM to 12 PM
//                                 </label>
//                               </td>
//                             </tr>
//                             <tr>
//                               <td>
//                                 <label className="form-check-label">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input"
//                                     value=""
//                                   />{' '}
//                                   Mon 10 AM to 12 PM
//                                 </label>
//                               </td>
//                               <td>
//                                 <label className="form-check-label">
//                                   &nbsp;&nbsp;&nbsp;
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input"
//                                     value=""
//                                   />{' '}
//                                   Tues 10 AM to 12 PM
//                                 </label>
//                               </td>
//                             </tr>
//                           </table>
//                           <br></br>
//                           <Button
//                       variant="primary"
//                       size="sm"
//                       className="btns btn02"
//                       onClick={togglePopup}
//                     >Submit
//                     </Button>{' '}
//                     {/* Popup Content */}
//       {isPopupOpen && (
//         <div className="popup">
//           <div className="popup-inner">
//           <button onClick={togglePopup} style={{width:21, float:'right', fontSize:10,paddingLeft:8, fontWeight:'bold'}}>X</button>
//             <h2>Congratulation!</h2>
//             <p>Your Slot has been Booked.</p>
            
//           </div>
//         </div>
//       )}
//                         </form>
//                         <h5 className="pat2">Or</h5>

//                         <Button
//                       variant="primary"
//                       size="sm"
//                       className="btns btn02"
//                       // onClick={handleToggleDiv}
//                     ><a
//                     href="/Meeting"
//                     className="cal"
//                     target="_blank"
//                     rel="noreferrer"
//                   >
//                       Connect With Doctor</a>
//                     </Button>{' '}
//                       </div>
//                     )}
//                   </div>

//                   <div className="col-lg-3 col-md-3 col-sm-12 col-12">
//                     <div style={{ height: 50 }}></div>
//                     <div className='for-mob-btn'>
//                     <Button
//                       variant="primary"
//                       size="sm"
//                       className="btns"
//                     >
//                       <a
//                         href="https://envizailab.com/doctor/video-call/"
//                         className="cal"
//                         target="_blank"
//                         rel="noreferrer"
//                       >
//                         Call Now
//                       </a>
//                     </Button>{' '}
//                     <div style={{ height: 5 }}></div>
//                     <Button
//                       variant="primary"
//                       size="sm"
//                       className="btns"
//                       onClick={handleToggleDiv}
//                     >
//                       Book An Appointment
//                     </Button>{' '}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <p>Loading...</p>
//         )}
//         <div style={{ height: 50 }}></div>
//       </div>
//     </div>
//   );
// };

// export default Doctors;

import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import img from './img/john.png';
import dt from './img/dt.png';
import rd from './img/rd.png';

const Doctors = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Function to toggle the popup
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  // State for toggling the appointment form div
  // const [isDivVisible, setIsDivVisible] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);

  const jsonData = [
    {
      dts: dt,
      img1: './img/john.png',
      name: 'John Doe',
      jd: 'Sergon',
      exp: '16 years Of Experience',
      consulting: 'Consultation fee at clinic',
      Timing: 'Mon - Fri | 8 AM to 2 PM',
    },
    {
      dts: rd,
      img1: '',
      name: 'Jennifer',
      jd: 'Sergon',
      exp: '10 years Of Experience',
      consulting: 'Consultation fee at clinic',
      Timing: 'Mon - Web | 10 AM to 12 PM',
    },
    {
      dts: rd,
      img1: './img/john.png',
      name: 'John Doe',
      jd: 'Sergon',
      exp: '8 years Of Experience',
      consulting: 'Consultation fee at clinic',
      Timing: 'Mon - Thurs | 8 AM to 2 PM',
    },
  ];

  useEffect(() => {
    // Simulate fetching data
    setTimeout(() => {
      setData(jsonData);
    }, 1000);
  }, []);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="container-fluid bg-clr bg-res" style={{ marginTop: -255 }}>
      <div className="container cntr">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div style={{ height: 50 }}></div>
            <h5 className="pat2" style={{ textAlign: 'left' }}>30 Doctors Near By You</h5>
            <p className="para2" style={{ textAlign: 'left' }}>
              Book appointments with minimum wait-time & verified doctor details
            </p>
          </div>
        </div>

        {data.length > 0 ? (
          data.map((user, index) => (
            <div className="row" key={index}>
              <div className="col-lg-10 col-md-10 col-sm-12 col-12 d-info">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 dr-img">
                    <div className="img-bdr">
                      <img src={img} alt="" className="mimg02" />
                      <img src={user.dts} alt="" className="mimg1" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="div-des">
                      <h4 className="pat">{user.name}</h4>
                      <p className="para2">{user.jd}</p>
                      <p className="para2">{user.exp}</p>
                      <p className="pat2">{user.consulting}</p>
                      <p className="pat4">&nbsp;{user.Timing}</p>
                    </div>
                    
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                      <div style={{ height: 50 }}></div>
                      <div className='for-mob-btn'>
                        <Button
                          variant="primary"
                          size="sm"
                          className="btns"
                        >
                          <a
                            href="/Meeting"
                            className="cal"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Call Now
                          </a>
                        </Button>{' '}
                        <div style={{ height: 5 }}></div>
                        <Button
                          variant="primary"
                          size="sm"
                          className="btns"
                          onClick={handleModalToggle}
                        >
                          Book An Appointment
                        </Button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Loading...</p>
        )}
        <div style={{ height: 50 }}></div>

        {/* Modal for Appointment Booking */}
        <Modal show={isModalOpen} onHide={handleModalToggle} centered>
          <Modal.Header closeButton>
            <Modal.Title>Book An Appointment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <table>
                <tbody>
                  <tr>
                    <td>Available Time Slots</td>
                  </tr>
                  <tr>
                    <td>
                      <label className="form-check-label lbl-size">
                        <input
                          type="checkbox"
                          className="form-check-input"
                        />{' '}
                        Mon 10 AM to 12 PM
                      </label>
                    </td>
                    <td>
                      <label className="form-check-label lbl-size">
                        <input
                          type="checkbox"
                          className="form-check-input"
                        />{' '}
                        Tues 10 AM to 12 PM
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="form-check-label lbl-size">
                        <input
                          type="checkbox"
                          className="form-check-input"
                        />{' '}
                        Wed 10 AM to 12 PM
                      </label>
                    </td>
                    <td>
                      <label className="form-check-label lbl-size">
                        <input
                          type="checkbox"
                          className="form-check-input"
                        />{' '}
                        Thurs 10 AM to 12 PM
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <div style={{textAlign:'center'}}>
              <Button
                variant="primary"
                size="sm"
                className="btns btn02"
                // onClick={handleModalToggle}
                onClick={togglePopup}
              >
                Submit
              </Button>&nbsp;&nbsp;&nbsp;
              <Button
              variant="primary"
              size="sm"
              className="btns btn02"
            >
              <a
                href="/Meeting"
                className="cal"
                target="_blank"
                rel="noreferrer"
              >
                Connect With Doctor
              </a>
            </Button></div>
            </form>
            {/* <h5 className="pat2">Or</h5>
            <Button
              variant="primary"
              size="sm"
              className="btns btn02"
            >
              <a
                href="/Meeting"
                className="cal"
                target="_blank"
                rel="noreferrer"
              >
                Connect With Doctor
              </a>
            </Button> */}
              {isPopupOpen && (
        <div className="popup">
          <div className="popup-inner">
         
            <h2>Congratulation!</h2>
            <p>Your Slot has been Booked.</p>
            
          </div>
        </div>
      )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Doctors;

