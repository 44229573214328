import React, { useState } from 'react'

const Signin = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, e.g., send data to API or validate it
        console.log(formData);
      };
  return (
    <section>
    <div className="container-fluid full-width-height" style={{background:'#CBE4F4'}}>
    <div style={{height:100}}></div>
<div className="container">
<div className="row rw">
{/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 "> */}
<div className="col-lg-5 col-md-5 col-sm-12 col-12 bg-img">
    {/* <div className='bg-img'> */}
    <h5 className='pat02'>Get 20% Off<hr></hr></h5>
    <p className='para2'>Book appointments with minimum wait-time & verified doctor details</p>



    {/* </div> */}
</div>
<div className="col-lg-5 col-md-5 col-sm-12 col-12 col-pd">
{/* <div className="signup-container"> */}
  <h2>Sign In</h2>
  <form onSubmit={handleSubmit}>
 
    <div className="form-group">
      <label>Email:</label>
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
      />
    </div>
    <div className="form-group">
      <label>Password:</label>
      <input
        type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        required
      />
    </div>
    <button type="submit">Sign In</button>
  </form>
  <p className='para2'>Don't have account? <a href="">Sign Up</a></p>
{/* </div> */}
</div>
{/* </div> */}
</div>
</div>
</div>
</section>
  )
}
export default Signin;
